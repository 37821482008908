
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.ChatContainer_ebf1bd372131ec48{transform:rotate(270deg)}.ChatContainer_ebf1bd372131ec48,.ChatNetspendContainer_133f2e0899f21357{cursor:pointer;position:fixed;bottom:40vh;right:-16px;z-index:1}.ChatDesktopIcon_281c1f816559c60a,.ChatNetspendDesktopIcon_cd8d9a6eb7e3c340{display:none}.ChatMobileIcon_37f20edb457908e1{display:block}.ChatNetspendMobileIcon_4beae9b90f757c07{cursor:pointer;position:fixed;bottom:10px;right:10px;width:40px;height:40px;background-color:#ff6b00;border:none;z-index:10000;border-radius:50%;box-shadow:0 2px 9pt 0 rgba(64,64,64,.2);padding:0;display:flex;align-items:center;justify-content:center}@media (min-width:600px){.ChatContainer_ebf1bd372131ec48{transform:none;bottom:20px;right:30px}.ChatMobileIcon_37f20edb457908e1,.ChatNetspendMobileIcon_4beae9b90f757c07{display:none}.ChatDesktopIcon_281c1f816559c60a{display:block}.ChatNetspendDesktopIcon_cd8d9a6eb7e3c340{cursor:pointer;position:fixed;bottom:10px;right:10px;width:52px;height:52px;background-color:#ff6b00;border:none;z-index:10000;border-radius:50%;box-shadow:0 2px 9pt 0 rgba(64,64,64,.2);padding:0;display:flex;align-items:center;justify-content:center}}`;
styleInject(_css)

/** ChatContainer Props */
export type ChatContainerCCM = {
  /** ChatContainer Component Custom Properties */
  // No custom properties found

  /** ChatContainer Modifier Flags */
  // No modifiers classes found
};
/** Base ChatContainer component */
export const ChatContainer: ComponentCreator<ChatContainerCCM> = createComponentCreator({
  "name": "ChatContainer",
  "base": "ChatContainer_ebf1bd372131ec48",
  "prop": {},
  "mod": {}
});


/** ChatNetspendContainer Props */
export type ChatNetspendContainerCCM = {
  /** ChatNetspendContainer Component Custom Properties */
  // No custom properties found

  /** ChatNetspendContainer Modifier Flags */
  // No modifiers classes found
};
/** Base ChatNetspendContainer component */
export const ChatNetspendContainer: ComponentCreator<ChatNetspendContainerCCM> = createComponentCreator({
  "name": "ChatNetspendContainer",
  "base": "ChatNetspendContainer_133f2e0899f21357",
  "prop": {},
  "mod": {}
});


/** ChatDesktopIcon Props */
export type ChatDesktopIconCCM = {
  /** ChatDesktopIcon Component Custom Properties */
  // No custom properties found

  /** ChatDesktopIcon Modifier Flags */
  // No modifiers classes found
};
/** Base ChatDesktopIcon component */
export const ChatDesktopIcon: ComponentCreator<ChatDesktopIconCCM> = createComponentCreator({
  "name": "ChatDesktopIcon",
  "base": "ChatDesktopIcon_281c1f816559c60a",
  "prop": {},
  "mod": {}
});


/** ChatNetspendDesktopIcon Props */
export type ChatNetspendDesktopIconCCM = {
  /** ChatNetspendDesktopIcon Component Custom Properties */
  // No custom properties found

  /** ChatNetspendDesktopIcon Modifier Flags */
  // No modifiers classes found
};
/** Base ChatNetspendDesktopIcon component */
export const ChatNetspendDesktopIcon: ComponentCreator<ChatNetspendDesktopIconCCM> = createComponentCreator({
  "name": "ChatNetspendDesktopIcon",
  "base": "ChatNetspendDesktopIcon_cd8d9a6eb7e3c340",
  "prop": {},
  "mod": {}
});


/** ChatMobileIcon Props */
export type ChatMobileIconCCM = {
  /** ChatMobileIcon Component Custom Properties */
  // No custom properties found

  /** ChatMobileIcon Modifier Flags */
  // No modifiers classes found
};
/** Base ChatMobileIcon component */
export const ChatMobileIcon: ComponentCreator<ChatMobileIconCCM> = createComponentCreator({
  "name": "ChatMobileIcon",
  "base": "ChatMobileIcon_37f20edb457908e1",
  "prop": {},
  "mod": {}
});


/** ChatNetspendMobileIcon Props */
export type ChatNetspendMobileIconCCM = {
  /** ChatNetspendMobileIcon Component Custom Properties */
  // No custom properties found

  /** ChatNetspendMobileIcon Modifier Flags */
  // No modifiers classes found
};
/** Base ChatNetspendMobileIcon component */
export const ChatNetspendMobileIcon: ComponentCreator<ChatNetspendMobileIconCCM> = createComponentCreator({
  "name": "ChatNetspendMobileIcon",
  "base": "ChatNetspendMobileIcon_4beae9b90f757c07",
  "prop": {},
  "mod": {}
});

